import React, { useEffect, useState } from "react";
import Loading from "../../components/Loading";
import styles from "./Marketplace.module.css";
import api from "./api/api";
import logo from "./assets/animation.png";
import BannerSlider from "./components/BannerSlider";
import ShopItem from "./components/ShopItem";
import firstBanner from "./assets/1.png";
import secondBanner from "./assets/2.png";
import thirdBanner from "./assets/3.png";

export default function MarketPlace() {
    const banners = [firstBanner, secondBanner, thirdBanner];
    const [helpflag, sethelpFlag] = useState(false);
    const [fileData, setFileData] = useState([]);
    const [actualRefs, setActualRef] = useState({});
    // const handlerForHelp = () => {
    //     if (helpflag === true) {
    //         sethelpFlag(false);
    //     } else {
    //         sethelpFlag(true);
    //     }
    // };

    const tg = window.Telegram.WebApp;
    const scrollToRef = (myRef) => {
        if (myRef.current) {
            myRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    };

    useEffect(() => {
        api.getMarket().then(r => setFileData(r.data));
        tg.BackButton.hide();

        var btn_color = '#ffffff';
        var txt_color = '#ffffff';

        tg.MainButton.setParams({ text: 'В корзине пусто', color: btn_color, text_color: txt_color, is_active: false, is_visible: false });
        // tg.MainButton.hide();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className={styles.marketContainer}>
            <div className={styles.marketHeader}>
                <div className={styles.marketHeaderContent}>
                    <img src={logo} alt=""></img>
                    <div className={styles.marketHeaderContentText}>
                        <h1>BOTIQUE</h1>
                        <p>Маркетплейс ваших желаний</p>
                    </div>
                </div>
            </div>
            <BannerSlider images={banners}></BannerSlider>
            <div className={styles.marketCatigories}>
                <div className={styles.marketCatigoriesColumn}>
                    <img src={require("./assets/Автотовары.png")} alt="" onClick={() => { scrollToRef(actualRefs["Автотовары"]) }}></img>
                    <img src={require("./assets/Хобби и творчество.png")} alt="" onClick={() => { scrollToRef(actualRefs["Хобби и творчество"]) }}></img>
                    <img src={require("./assets/Дом и сад.png")} alt="" onClick={() => { scrollToRef(actualRefs["Дом и сад"]) }}></img>
                </div>
                <div className={styles.marketCatigoriesColumn}>
                    <img src={require("./assets/Красота и здоровье.png")} alt="" onClick={() => { scrollToRef(actualRefs["Красота и здоровье"]) }}></img>
                    <img src={require("./assets/Для животных.png")} alt="" onClick={() => { scrollToRef(actualRefs["Товары для животных"]) }}></img>
                    <img src={require("./assets/Одежда и обувь.png")} alt="" onClick={() => { scrollToRef(actualRefs["Одежда и обувь"]) }}></img>
                </div>
            </div>
            {fileData.length === 0 ? <Loading /> : <div className={styles.marketShops}>
                {fileData.map((el, idx) => <ShopItem item={el} key={idx} refFunc={setActualRef} refs={actualRefs} />)}
                <div className={styles.helpInfo}>
                    <p>Мы работаем над привлечением поставщиков, завтра их будет больше!</p>
                </div>
                {/* <div className={styles.helpBlock} onClick={handlerForHelp}>
                    <div className={styles.helpBlockContent}>
                        <span>Информация для пользователей</span>
                        <img src={arow} alt=""></img>
                    </div>
                </div> */}
                {helpflag === false ? null : <div className={styles.helpText}>
                    <p>Разнообразный и богатый опыт говорит нам, что курс на социально-ориентированный национальный проект обеспечивает актуальность соответствующих условий активизации. Есть над чем задуматься: элементы политического процесса указаны как претенденты на роль ключевых факторов. Но перспективное планирование создаёт предпосылки для экономической целесообразности принимаемых решений.</p>
                    <p>В своём стремлении улучшить пользовательский опыт мы упускаем, что некоторые особенности внутренней политики призваны к ответу. Наше дело не так однозначно, как может показаться: курс на социально-ориентированный национальный проект требует от нас анализа распределения внутренних резервов и ресурсов. Следует отметить, что курс на социально-ориентированный национальный проект прекрасно подходит для реализации новых предложений. И нет сомнений, что базовые сценарии поведения пользователей лишь добавляют фракционных разногласий и объективно рассмотрены соответствующими инстанциями. Ясность нашей позиции очевидна: укрепление и развитие внутренней структуры позволяет оценить значение распределения внутренних резервов и ресурсов.</p>
                    <p>И нет сомнений, что сделанные на базе интернет-аналитики выводы представлены в исключительно положительном свете. Учитывая ключевые сценарии поведения, сплочённость команды профессионалов способствует повышению качества системы массового участия. Значимость этих проблем настолько очевидна, что перспективное планирование обеспечивает широкому кругу (специалистов) участие в формировании кластеризации усилий. Равным образом, повышение уровня гражданского сознания прекрасно подходит для реализации переосмысления внешнеэкономических политик. Однозначно, непосредственные участники технического прогресса являются только методом политического участия и рассмотрены исключительно в разрезе маркетинговых и финансовых предпосылок.</p>
                    <span onClick={() => sethelpFlag(false)}>свернуть</span>
                </div>}
            </div>}
            {/* <SearchComponent /> */}
        </div>
    )
};
