import React, { useEffect, useState } from 'react';
import styles from "./Category.module.css";

const CategoryComponent = ({ categories, handler, scrollDistance, scrollPosition, preSelectedCategory, isCategory }) => {
  const [selectedCategory, setSelectedCategory] = useState('');
  const [uniqueCategories, setInfo] = useState(["Все", ...new Set(categories)]);
  const [allCategories] = useState(categories);

  const handleClick = (category) => {
    setSelectedCategory(category);
  };

  useEffect(() => {
    var tempUniqueCategories;
    if (isCategory) {
      tempUniqueCategories = ["Все", ...new Set(categories)];
    } else {
      tempUniqueCategories = [...new Set(categories)];
    }
    if (tempUniqueCategories.length > 2) {
      setInfo(tempUniqueCategories);
    } else {
      setSelectedCategory("Все");
      setInfo([...new Set(categories)]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categories, allCategories]);

  useEffect(() => {
    if (isCategory) {
      setSelectedCategory(preSelectedCategory === null ? 'Все' : preSelectedCategory);
    } else {
      setSelectedCategory(preSelectedCategory === null ? 'Все' : preSelectedCategory);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categories]);

  return (
    <div className={`${styles.fixedBlock}`}>
      <div className={`${styles.container}`}>
        {uniqueCategories.length > 1 ? uniqueCategories.map((category, index) => (
          <span
            key={index} onClick={(e) => { handleClick(category); handler(e); }}
            className={selectedCategory === category ? styles.item + " " + styles.active : styles.item}
          >
            {category}
          </span>
        )) : null}
      </div>
      {uniqueCategories.length > 0 ? < div className={`${styles.scrollLine} ${scrollPosition >= scrollDistance ? styles.visible : ''}`} /> : null}
    </div>
  );
};

export default CategoryComponent;