import React, { memo, useState, useEffect, useRef } from 'react';
import styles from './BannerSlider.module.css';

const BannerSlider = memo(({ images }) => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [startX, setStartX] = useState(null);
    const timerRef = useRef(null);
    const touchEndTimerRef = useRef(null);

    const handleNextSlide = () => {
        setCurrentIndex(prevIndex => (prevIndex + 1) % images.length);
    };

    useEffect(() => {
        // Установка интервала для автоматической прокрутки
        timerRef.current = setInterval(handleNextSlide, 3000);

        // Очистка интервала при размонтировании компонента
        return () => {
            clearInterval(timerRef.current);
        };
    }, [currentIndex]); // Зависимость от currentIndex для перезапуска интервала при изменении текущего индекса

    const handleTouchStart = (e) => {
        clearInterval(timerRef.current);
        clearTimeout(touchEndTimerRef.current);
        setStartX(e.touches[0].clientX);
    };

    const handleTouchEnd = () => {
        // После окончания касания возобновляем автоматическую прокрутку
        touchEndTimerRef.current = setTimeout(() => {
            timerRef.current = setInterval(handleNextSlide, 3000);
        }, 3000);
    };

    if (images.length > 10) {
        images = images.slice(0, 9);
    }

    const handleTouchMove = (e) => {
        if (startX !== null) {
            const deltaX = e.touches[0].clientX - startX;

            // Свайп влево
            if (deltaX > 50 && currentIndex > 0) {
                setCurrentIndex(currentIndex - 1);
                setStartX(null);
            }
            // Свайп вправо
            else if (deltaX < -50 && currentIndex < images.length - 1) {
                setCurrentIndex(currentIndex + 1);
                setStartX(null);
            }
        }
    };

    return (
        <div
            className={styles.slider}
            onTouchStart={handleTouchStart}
            onTouchMove={handleTouchMove}
            onTouchEnd={handleTouchEnd}
        >
            <div className={styles.slidesContainer} style={{ transform: `translateX(-${currentIndex * 100}%)` }}>
                {images.map((image, index) => (
                    <div key={index} className={styles.slide}>
                        <div className={styles.slideImg}>
                            <img src={image} alt={`Slide ${index + 1}`} />
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
});

export default BannerSlider;
