import React, { useEffect, useState } from 'react';
import logo from '../../logo_full.svg';
import Botique from '../../logo_small.svg';
import Window from '../Window/Window';
import styles from './Game.module.css';

const Game = () => {
    const [score, setScore] = useState(0);
    const [position, setPosition] = useState((window.innerWidth - 120) / 2);

    const [xWindowPos, setXWindowPos] = useState(0);
    const [yWindowPos, setYWindowPos] = useState(40); // window.innerHeight * 0.7 - 40 - 10
    const [wallHeight] = useState(50);

    const [gameOver, setGameOver] = useState(false);
    const [bestScore, setBestScore] = useState(null);

    const moveLeft = () => {
        if (!gameOver) {
            if (position > 0) {
                setPosition(prevPosition => prevPosition - 10);
            }
        }
    };

    const moveRight = () => {
        if (!gameOver) {
            const screenWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
            const imageWidth = 120;
            if (position + 10 < screenWidth - imageWidth) {
                setPosition(prevPosition => prevPosition + 10);
            }
        }

    };

    useEffect(() => {
        const storedBestScore = localStorage.getItem('bestScore');

        if (storedBestScore) {
            setBestScore(parseInt(storedBestScore, 10));
        }
    }, []);

    useEffect(() => {
        if (score > bestScore) {
            localStorage.setItem('bestScore', score.toString());
            setBestScore(score);
        }
    }, [score, bestScore]);

    useEffect(() => {
        let interval;

        if (!gameOver) {
            interval = setInterval(() => {
                if (yWindowPos >= window.innerHeight * 0.5 - wallHeight) {
                    if ((position < xWindowPos || position + 120 > xWindowPos + 200)) {
                        // Game over
                        setGameOver(true);
                        clearInterval(interval);

                    }
                }

                setYWindowPos(prevYPos => prevYPos + 2);

                if (yWindowPos >= window.innerHeight * 0.7 - wallHeight) {
                    setScore(prevScore => prevScore + 1);
                    setYWindowPos(50);
                    setXWindowPos(Math.floor(Math.random() * 100));
                }
            }, 10);
        }

        return () => clearInterval(interval);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [yWindowPos, gameOver]);

    const restartGame = () => {
        setScore(0);
        setYWindowPos(50);
        setXWindowPos(Math.floor(Math.random() * 100));
        setGameOver(false);
    };

    return (
        <div className={styles.game}>
            <div className={styles.gameHeader}>
                <div className={styles.headerLogo}>
                    <img src={logo} alt="logo" />
                    <span>Блиц</span>
                </div>
                <div className={styles.score}>Ваши Botique коины: <span>{score}</span></div>
            </div>
            <div className={styles.gameContainer}>
                <Window xPosition={xWindowPos} screenWidth={window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth} yPosition={yWindowPos} wallHeight={wallHeight} />
                <div className={styles.botique} style={{ left: position + 'px' }}>
                    <img src={Botique} alt="Logo" />
                </div>
            </div>
            <div className={styles.buttons}>
                <button className={styles.leftBtn} onClick={moveLeft}>{"<"}</button>
                <button className={styles.rightBtn} onClick={moveRight}>{">"}</button>
            </div>
            {gameOver && (
                <div className={styles.gameOverContainer}>
                    <p className={styles.gameOverMessage}>Игра окончена!</p>
                    {localStorage.getItem('bestScore') && <p>Лучший результат: {bestScore}</p>}
                    <button className={styles.restartButton} onClick={restartGame}>Начать заново</button>
                </div>
            )}
        </div>
    );
};

export default Game;
