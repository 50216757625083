import React, { useState,useRef,useEffect } from "react";
import { useNavigate } from "react-router-dom";
import arrow from "../assets/Frame 1237.png";
import sale from "../assets/sale-icon.svg";
import ImageSlider from "./ImageSlider";
import styles from "./ShopItem.module.css";
// import StarRating from "./StarsComponent";

function ShopItem({ item,refFunc,refs }) {
    const [flagOpen, setFlagOpen] = useState(true);
    const catRef = useRef();
    const nav = useNavigate();
    const tg = window.Telegram.WebApp;

    const handlerForCat = () => {
        if (flagOpen === true) {
            setFlagOpen(false);
        } else {
            setFlagOpen(true);
        }
    };
    useEffect(() => {
            let newValue = refs;
            newValue[item.category] = catRef;
            refFunc(newValue);

    },[]);

    const formatPriceWithThousandSeparator = (price) => {
        return Number(price).toFixed().toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    };

    return (
        <div className={styles.shopItemContainer} ref={catRef}>
            <div className={styles.shopItemCat} onClick={handlerForCat}>
                <div className={styles.shopItemCatContent}>
                    <span>{item.category}</span>
                    <img src={arrow} alt=""></img>
                </div>
            </div>
            {flagOpen === false ? null : <div className={styles.shopsContainer}>
                {item.stores.map((el, idx) => <div key={idx} className={styles.shopItemContentShop}>
                    <div className={styles.shopItemContentInfo}>
                        <div className={styles.shopItemContentInfoContent}>
                            <img src={el.store_info.logo} alt=""></img>
                            <div className={styles.shopItemContentInfoContentText}>
                                <h2>{el.store_info.name}</h2>
                                <p>{el.store_info.description}</p>
                            </div>
                        </div>
                    </div>
                    <div className={styles.shopProducts}>
                        {el.products.length === 1 ?
                            <div key={idx} className={styles.shopSingleProduct}>
                                <div className={styles.productPhotos}>
                                    <ImageSlider images={el.products[0].photos} />
                                    {el.products[0].price === el.products[0].total_price ? null : <img src={sale} alt="" className={styles.shopProductSale}></img>}
                                </div>
                                <div className={styles.productDescription}>
                                    <div className={styles.shopProductPrices}>
                                        <span className={styles.shopProductPrice}>{formatPriceWithThousandSeparator(el.products[0].total_price)}</span>
                                        {el.products[0].price === el.products[0].total_price ? null : <span className={styles.shopProductOldPrice}>{formatPriceWithThousandSeparator(el.products[0].price)}</span>}
                                    </div>
                                    <span className={styles.shopProductName}>{el.products[0].name.length >= 50 ? el.products[0].name.slice(0, 40) + "..." : el.products[0].name}</span>
                                </div>
                            </div>
                            : el.products.map((product, idx) =>
                                <div key={idx} className={styles.shopProduct}>
                                    {product.price === product.total_price ? null : <img src={sale} alt="" className={styles.shopProductSale}></img>}
                                    <ImageSlider images={product.photos} />
                                    <div className={styles.shopProductPrices}>
                                        <span className={styles.shopProductPrice}>{formatPriceWithThousandSeparator(product.total_price)}</span>
                                        {product.price === product.total_price ? null : <span className={styles.shopProductOldPrice}>{formatPriceWithThousandSeparator(product.price)}</span>}
                                    </div>
                                    <span className={styles.shopProductName}>{product.name.length >= 50 ? product.name.slice(0, 40) + "..." : product.name}</span>
                                </div>
                            )}
                    </div>
                    {/* <div className={styles.shopProductRating}>
                        <span>Оценка магазина</span>
                        <StarRating rating={5} />
                    </div> */}
                    <button className={styles.shopButton} onClick={() => { tg.BackButton.onClick(() => nav(`/`)); tg.BackButton.show(); nav(`store/${el.store_info.id}`); }}>Перейти в магазин</button>
                </div>)}
            </div>}

        </div>
    )
}
export default ShopItem;