import React from 'react';
import styles from './BotiqueLabel.module.css';
import logo from './botique_logo.svg'; // Импорт логотипа Botique
import { Link } from 'react-router-dom';

const BotiqueLabel = () => {
    return (
        <div className={styles.container}>
            <div className={styles.label}>
                <span className={styles.text}>
                    Создано на платформе
                </span>
                <Link to={'https://t.me/+RPH4iEo1wqs1OTQy'}><img src={logo} alt="Botique Logo" className={styles.logo} /></Link>
            </div>
        </div>
    );
};

export default BotiqueLabel;
