import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import "../../../Constants.css";
import BannersComponent from './BannersComponent';
import styles from './CatalogPage.module.css';
import CategoryComponentV2 from './CategoryComponentV2';
import ProductsComponent from './ProductsComponent';
import StoreInfoComponent from './StoreInfoComponent';

const CatalogPage = ({ data, setCartCount, hanlderForCategories, hanlderForSubCategories, cartData, setCartData, preSelectedCategory, preSelectedSubCategory }) => {

    const { storeID } = useParams();
    const bannersDiv = useRef(null);
    const storeDiv = useRef(null);
    const [invertFlag, setInvertFlag] = useState(false);

    const scrollContainerRef = useRef(null);
    const tg = window.Telegram.WebApp;

    const toggleInvertFlag = () => {
        if (invertFlag) {
            tg.setHeaderColor('#FFFFFF');
        } else {
            tg.setHeaderColor('#301934');
        }
        setInvertFlag(prev => {
            localStorage.setItem('invert_style', (!prev).toString());
            return !prev;
        });
    };

    useEffect(() => {
        const savedInvertState = localStorage.getItem('invert_style');
        if (savedInvertState === 'true') {
            setInvertFlag(true);
            tg.setHeaderColor('#301934');
        } else {
            tg.setHeaderColor('#FFFFFF');
        }
        tg.MainButton.setParams({ color: '#800080' });
    }, []);


    return (
        <div className={`${styles.container} ${invertFlag && styles.invert}`} ref={scrollContainerRef}>
            <BannersComponent banners={data.banners} divRef={bannersDiv} />
            <StoreInfoComponent storeInfo={data.storeInfo} divRef={storeDiv} toggleInvertFlag={toggleInvertFlag} />
            <CategoryComponentV2 categories={['Все', ...data.categories]} subcategories={data.subcategories} handleSelectCategory={hanlderForCategories} handleSelectSubCategory={hanlderForSubCategories} defaultCategory={preSelectedCategory} defaultSubCategory={preSelectedSubCategory} />
            <div className={styles.productsContainer}>
                <ProductsComponent products={data.products} storeID={storeID} setCartCount={setCartCount} cartData={cartData} setCartData={setCartData} />
            </div>
        </div>
    );
};

export default CatalogPage;
