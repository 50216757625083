import React, { memo } from 'react';
import { useNavigate } from 'react-router-dom';
import { v4 as uuid } from "uuid";
import ImageSlider from './ImageSlider';
import styles from './ProductsComponent.module.css';
import ProductLabelComponent from '../../ProductLabelComponent';


const ProductCard = memo(({ product, storeID }) => {

    const nav = useNavigate();
    const formatPriceWithThousandSeparator = (price) => {
        return Number(price).toFixed().toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    };

    return (
        <div className={styles.productCard} key={uuid()} onClick={() => { nav(`/store/${storeID}/product/${product.product}`); }}>
            <ImageSlider key={product.id} images={product.photos} />
            <div className={styles.price}>
                {product.total_price !== product.price ? (
                    <div className={styles.priceLeft}>
                        <span className={styles.originalPrice}>{formatPriceWithThousandSeparator(product.total_price)}</span>
                        <span className={styles.discountPrice}>{formatPriceWithThousandSeparator(product.price)}</span>
                    </div>
                ) : (
                    <span className={styles.originalPrice}>{formatPriceWithThousandSeparator(product.total_price)}</span>
                )}
                {product.labels.length > 0 && <div className={styles.labels}>{product.labels.map(label => (<ProductLabelComponent labelStyle={label} />))}</div>}
            </div>

            <p className={styles.description}>{`${product.name}`}</p>
        </div>
    );
});

const ProductsComponent = ({ products, storeID }) => {
    return (
        <div className={`${styles.products}`}>
            {products.map((product) => (
                <ProductCard
                    key={product.id}
                    product={product}
                    storeID={storeID}
                />
            ))}

            {products.length === 0 && <div className={styles.emptyCatalog}><span>Товары не найдены</span></div>}
        </div>
    );
};

export default ProductsComponent;
