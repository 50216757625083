import axios from "axios";
import { baseAPIURL } from "../../../config/config";


const api = {
    // Marketplace API v2
    getMarket: async () => {
        try {
            const response = await axios.get(`${baseAPIURL}/telegram/market/`).catch((e) => { });
            return response;
        } catch (error) {
            throw error;
        }
    },
}

export default api;