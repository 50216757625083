import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { v4 as uuid } from "uuid";
import ProductLabelComponent from '../../ProductLabelComponent';
import ImageSlider from './ImageSlider';
import styles from './ProductsComponent.module.css';

const ProductsComponent = ({ products, storeID, scrollDistance, scrollPosition }) => {

    const nav = useNavigate();

    const formatPriceWithThousandSeparator = (price) => {
        return Number(price).toFixed().toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    };

    const memoizedProductCards = useMemo(() => {
        return products.map((product) => (
            <div className={styles.productCard} key={uuid()}>

                <ImageSlider images={product.photos} />

                <div className={styles.price}>
                    {product.price !== product.total_price ? (
                        <>
                            <span className={styles.discountPrice}>{formatPriceWithThousandSeparator(product.price)}</span>
                            <span className={styles.originalPrice}>{formatPriceWithThousandSeparator(product.total_price)}</span>
                        </>
                    ) : (
                        <span className={styles.originalPrice}>{formatPriceWithThousandSeparator(product.total_price)}</span>
                    )}
                </div>

                <p className={styles.description}>{`${product.name}`}</p>

                <button className={styles.viewButton} onClick={() => { nav(`/store/${storeID}/product/${product.product}`); }}>Посмотреть</button>
                {product.labels.length > 0 && <div className={styles.labels}>{product.labels.map(label => (<ProductLabelComponent labelStyle={label} />))}</div>}
            </div>
        ));
    }, [nav, products, storeID]);

    return (
        <div className={`${styles.products} ${scrollPosition >= scrollDistance ? styles.offset : ''}`}>
            {memoizedProductCards}
            {products.length === 0 && <div className={styles.emptyCatalog}><span>К сожалению товары не найдены...</span></div>}
        </div>
    );
};

export default ProductsComponent;
