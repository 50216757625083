import { useState, useCallback } from 'react';

let notificationTimeout;

const useNotification = () => {
    const [notification, setNotification] = useState(null);

    const notify = useCallback((message) => {
        if (notificationTimeout) {
            clearTimeout(notificationTimeout);
        }
        setNotification(message);
        notificationTimeout = setTimeout(() => {
            setNotification(null);
        }, 3000); // Уведомление будет отображаться 5 секунд
    }, []);

    return { notification, notify };
};

export default useNotification;
