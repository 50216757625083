import axios from "axios";
import { baseAPIURL } from "../../../config/config";


const catalogApi = {
    getStoreInfo: async (storeID) => {
        try {
            const response = await axios.get(`${baseAPIURL}/telegram/store/${storeID}/info/`).catch((e) => { });
            return response;
        } catch (error) {
            throw error;
        }
    },
    getStoreProducts: async (storeID, page, category, subcategory, query) => {
        try {
            const response = await axios.get(`${baseAPIURL}/telegram/store/${storeID}/catalog/`, { params: { page: page, category: category, subcategory: subcategory, q: query } }).catch((e) => { });
            return response;
        } catch (error) {
            throw error;
        }
    },
    getSubCategories: async (storeID, category) => {
        try {
            const response = await axios.get(`${baseAPIURL}/telegram/store/subcategories`, { params: { store: storeID, category: category } }).catch((e) => { });
            return response;
        } catch (error) {
            throw error;
        }
    },
}

export default catalogApi;