import axios from 'axios';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import React, { useEffect, useRef, useState } from 'react';
import { MapContainer, Marker, Popup, TileLayer } from 'react-leaflet';
import styles from './PointSelectionMap.module.css';
import boxBerryLogo from './boxberry.jpg';
import mapPointIcon from './mapLocations.svg';


const PointSelectionMap = ({ onSelect }) => {
    const [points, setPoints] = useState([]);

    const [selectedPoint, setSelectedPoint] = useState(null);

    const testMarkerIcon = new L.Icon({
        iconUrl: mapPointIcon,
        iconSize: [30, 30],
        iconAnchor: [15, 30],
        popupAnchor: [0, -30],
    });

    const mapStyle = {
        height: '94vh',
        width: '100%',
    };

    const [userLocation, setUserLocation] = useState(null);

    const handleSelect = (point) => {
        if (point) {
            onSelect(point);
        }
    };

    useEffect(() => {
        if ('geolocation' in navigator) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    setUserLocation([position.coords.latitude, position.coords.longitude]);
                },
                (error) => {
                    console.error('Error getting user location:', error);
                }
            );
        } else {
            console.error('Geolocation is not supported by your browser');
        }
        axios.get('https://points.thebotique.ru/api/v1/short/points')
            .then((r) => {
                setPoints(r.data);
            })
    }, []);

    const mapRef = useRef();

    useEffect(() => {
        if (mapRef.current && userLocation) {
            const map = mapRef.current;
            map.setView(userLocation, map.getZoom());
        }
    }, [userLocation]);

    const handleMapClick = (event, pointCode) => {
        axios.post('https://points.thebotique.ru/api/v1/points', `["${pointCode}"]`, { headers: { 'Content-Type': 'text/plain' } })
            .then((r) => { setSelectedPoint(r.data[0]) });
    };

    return (
        <>
            <MapContainer center={userLocation || [55.733, 37.6]} zoom={14} style={mapStyle} attributionControl={false} ref={mapRef} onClick={handleMapClick}>
                <TileLayer
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                />
                {points.map((point, index) => (
                    <Marker
                        key={index}
                        position={[point.lat, point.long]}
                        icon={testMarkerIcon}
                        eventHandlers={{ click: (event) => handleMapClick(event, point.code) }}
                    >
                        <Popup key={index}>
                            <div className={styles.pointStyle}>
                                <img src={boxBerryLogo} alt={point.name} className={styles.pointImage} />
                                <div className={styles.pointInfo}>
                                    <h2>ПВЗ Boxberry</h2>
                                    <p><strong>Адрес:</strong> {selectedPoint?.Address}</p>
                                    <p><strong>Режим работы:</strong> {selectedPoint?.WorkShedule}</p>
                                    <div className={styles.buttons}>
                                        <button type='button' onClick={() => handleSelect(selectedPoint)}>Выбрать</button>
                                    </div>
                                </div>
                            </div>
                        </Popup>
                    </Marker>
                ))}
            </MapContainer>
        </>
    );
};

export default PointSelectionMap;
