import React, { useEffect, useState } from 'react';
import styles from './ImageSlider.module.css';
import Modal from './Modal';

const ImageSlider = ({ images }) => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [selectedImage, setSelectedImage] = useState(null);
    const [startX, setStartX] = useState(null);

    const handleTouchStart = (e) => {
        setStartX(e.touches[0].clientX);
    };

    const handlePrevClick = () => {
        setCurrentIndex(prevIndex => (prevIndex === 0 ? images.length - 1 : prevIndex - 1));
    };

    const handleNextClick = () => {
        setCurrentIndex(prevIndex => (prevIndex === images.length - 1 ? 0 : prevIndex + 1));
    };

    const handleTouchMove = (e) => {
        if (startX !== null) {
            const deltaX = e.touches[0].clientX - startX;

            // Свайп влево
            if (deltaX > 50 && currentIndex > 0) {
                setCurrentIndex(currentIndex - 1);
                setStartX(null);
            }
            // Свайп вправо
            else if (deltaX < -50 && currentIndex < images.length - 1) {
                setCurrentIndex(currentIndex + 1);
                setStartX(null);
            }
        }
    };

    const handleImageClick = (url) => {
        setSelectedImage(url);
        document.body.style.overflow = 'hidden';
    };

    const handleCloseModal = () => {
        setSelectedImage(null);
        document.body.style.overflow = '';
    };

    useEffect(() => { setCurrentIndex(0); setStartX(null); }, [images]);

    return (
        <div
            className={styles.slider}
            onTouchStart={handleTouchStart}
            onTouchMove={handleTouchMove}
        >
            <div className={styles.slidesContainer} style={{ transform: `translateX(-${currentIndex * 100}%)` }}>
                {images?.map((image, index) => (
                    <div key={index} className={styles.slide} >
                        <div className={styles.slideImg} onClick={() => handleImageClick(image.url)}>
                            <img src={image.url} alt={`Slide ${index + 1}`} />
                        </div>
                    </div>
                ))}
            </div>

            {
                images?.length > 1 ?
                    <>
                        <button className={styles.arrowLeft} onClick={handlePrevClick}>
                            &lt;
                        </button>
                        <button className={styles.arrowRight} onClick={handleNextClick}>
                            &gt;
                        </button>
                        <div className={styles.dotsContainer}>
                            {images.map((_, index) => (
                                <span
                                    key={index}
                                    className={`${styles.dot} ${index === currentIndex ? styles.activeDot : ''}`}
                                    onClick={() => setCurrentIndex(index)}
                                ></span>
                            ))}
                        </div>
                    </>
                    : null
            }
            <Modal imageUrl={selectedImage} onClose={handleCloseModal} />
        </div>
    );
};

export default ImageSlider;
