import React from 'react';
import Modal from 'react-modal';
import styles from './CartModal.module.css';

Modal.setAppElement('#root');

function CartModal({ showModal, onClose, productCombosVarNames, selectedOptions, setSelectedOptions, handleAddToCart, isAdded, setIsAdded, goToCart }) {
    if (!showModal) {
        return null;
    }

    const closeModal = () => {
        onClose();
    };

    const cartClick = () => {
        handleAddToCart();
    };

    const handleClickOption = (option, var_type) => {
        setIsAdded(false);
        setSelectedOptions(prev => ({
            ...prev,
            [var_type]: option
        }));
    };

    return (
        <Modal
            isOpen={showModal}
            onRequestClose={closeModal}
            className={styles.modalContent}
            overlayClassName={styles.modalOverlay}
        >
            <div className={styles.optionsArea}>
                {productCombosVarNames.var1.options.length > 1 ?
                    (
                        <div className={styles.singleOptionArea}>
                            <p>{productCombosVarNames.var1?.name}:</p>
                            <div className={styles.optionsContainer}>
                                <div className={styles.optionList}>
                                    {productCombosVarNames.var1?.options.map((option, index) => (
                                        <span
                                            key={index}
                                            onClick={() => { handleClickOption(option, 'var1'); }}
                                            className={selectedOptions.var1 === option ? styles.option + " " + styles.active : styles.option}
                                        >
                                            {option}
                                        </span>
                                    ))}
                                </div>
                            </div>
                        </div>
                    ) : (null)}

                {
                    productCombosVarNames.var2.options.length > 1 ? (
                        <div className={styles.singleOptionArea}>
                            <p>{productCombosVarNames.var2?.name}:</p>
                            <div className={styles.optionList}>
                                {productCombosVarNames.var2?.options.map((option, index) => (
                                    <span
                                        key={index}
                                        onClick={() => { handleClickOption(option, 'var2'); }}
                                        className={selectedOptions.var2 === option ? styles.option + " " + styles.active : styles.option}
                                    >
                                        {option}
                                    </span>
                                ))}
                            </div>
                        </div>
                    ) : null
                }
            </div>

            <div className={styles.buttonsArea}>
                {!isAdded ? (<span onClick={cartClick}>Добавить в корзину</span>) : (<span onClick={goToCart}>Перейти в корзину</span>)}
                <span onClick={closeModal}>Вернуться в каталог</span>
            </div>
        </Modal>
    );
}

export default CartModal;
