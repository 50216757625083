import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import api from '../../api/api';
import CheckoutViewBox from '../../components/Boxberry/CheckoutView/CheckoutView';
import CheckoutViewList from '../../components/List/CheckoutView/CheckoutView';
import CheckoutViewAstroknight from '../../components/Astroknight/CheckoutView/CheckoutView';
import Loading from '../../components/Loading';
import CheckoutViewWhite from '../../components/White/CheckoutView/CheckoutView';
import { useTelegram } from '../../components/useTelegram';

function CheckoutPage() {

    const [nextClose, setNextClose] = useState(false);

    const { storeID } = useParams();
    const { user } = useTelegram();
    const nav = useNavigate();

    const [orderFields, setOrderFields] = useState({ name: true, email: true, phone: true, inn: false, comment: false, custom_date: false, custom_date_title: '' });

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [inn, setInn] = useState('');
    const [comment, setComment] = useState('');
    const [selectedDate, setSelectedDate] = useState(null);

    const [deliveryType, setDeliveryType] = useState('1');
    const [isDeliveryFree, setIsDeliveryFree] = useState(false);
    const [sellerDeliveryPrice, setSellerDeliveryPrice] = useState(0);
    const [deliveryAddress, setDeliveryAddress] = useState('');

    const [cartItems, setCartItems] = useState([]);
    const [minOrderPrice, setMinOrderPrice] = useState(0);
    const [freeDeliveryPrice, setFreeDeliveryPrice] = useState(0);
    const [paymentMessage, setPaymentMessage] = useState('');

    const [showModal, setShowModal] = useState(false);
    const [showText, setShowText] = useState('Продавец получил ваш заказ, скоро с вами свяжутся!');
    const [showTitle, setShowTitle] = useState('Спасибо за заказ!');

    const [template, setTemplate] = useState("0");

    const [shippingPrice, setShippingPrice] = useState(0);

    const [isPaymentConnected, setIsPaymentConnected] = useState(false);

    const [selectedPoint, setSelectedPoint] = useState(null);
    const [isMapOpen, setIsMapOpen] = useState(false);

    const [selectedOption, setSelectedOption] = useState(null);

    const [isLoading, setIsLoading] = useState(false);

    const [address, setAddress] = useState({
        city: '',
        street: '',
        house: '',
        apartment: '',
    });

    useEffect(() => {
        const tg = window.Telegram.WebApp;
        tg.MainButton.setParams({ is_active: false, is_visible: false });

        // Получение кода текущего шаблона 
        const template_code = localStorage.getItem('template_code');
        if (template !== undefined) {
            setTemplate(template_code);
        }

        tg.BackButton.onClick(() => nav(`/store/${storeID}/cart`));
        tg.BackButton.show();

        // Получение товаров из корзины
        api.getCart(user.id.toString(), storeID).then((r) => {
            if (r.status === 200) {
                setCartItems(r.data.items);
            } else if (r.status === 204) {
                setShowTitle('Ваша корзина пуста...');
                setShowText('Для оформления заказа - выберите товары и сложите их в корзину');
                setShowModal(true);
            }
        });

        api.minOrderPrice(storeID).then((r) => {
            const min_order_price = r?.data?.min_order_price;
            setMinOrderPrice(prev => min_order_price);
        });

        api.getFreeDeliveryPrice(storeID).then((r) => {
            const free_delivery_price = r?.data?.free_delivery_price;
            setFreeDeliveryPrice(prev => free_delivery_price);
        });

        api.getPaymentMessage(storeID).then((r) => {
            const payment_message = r?.data?.payment_message;
            setPaymentMessage(prev => payment_message);
        });

        // Получение информации о доставке
        api.getDeliveryInfo(storeID).then((response) => {
            if (response.data) {
                setDeliveryType(response.data['delivery_type']);
                setIsDeliveryFree(response.data['is_delivery_free']);
                setSellerDeliveryPrice(response.data['delivery_price']);

                if (response.data['delivery_type'] === 'delivery' | response.data['delivery_type'].includes('3')) {
                    setSelectedOption('seller');
                }
                if (response.data['delivery_type'].includes('2')) {
                    setSelectedOption('boxberry');
                }
                if (response.data['delivery_type'] === 'pickup' | response.data['delivery_type'].includes('1')) {
                    setDeliveryAddress(response.data['delivery_option']);
                    setSelectedOption('pickup');
                }
                if (response.data['delivery_type'].includes('4')) {
                    setSelectedOption('online');
                }

                // Заполнение полей, если есть
                api.getCartInfo(user.id.toString(), storeID).then((r) => {
                    setName(r.data?.name);
                    setPhone(r.data?.phone);
                    setEmail(r.data?.email);
                });
            }
        });

        // Получение информации о полях формы заказа
        api.getOrderForm(storeID).then(({ data }) => {
            setOrderFields(data);

            api.getShopID(storeID).then(tinkoff_r => {
                try {
                    if (tinkoff_r.status === 200) {
                        setOrderFields((prev) => ({ ...prev, phone: true, email: true }));
                        setIsPaymentConnected(true);
                    }
                } catch { }
            });
        });


        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [storeID]);

    const handleSelectPoint = (point) => {
        setSelectedPoint(point);
        setIsMapOpen(false);
        setAddress(point.Address);

        const box_sizes = [];
        cartItems.forEach(item => {
            for (let i = 0; i < item.quantity; i++) {
                box_sizes.push({
                    depth: item.depth,
                    height: item.height,
                    weight: item.weight,
                    width: item.width,
                });
            }
        });

        if (calculateTotalPrice() < freeDeliveryPrice) {
            api.calculateShippingPrice(storeID, box_sizes, point.Code).then((r) => {
                if (r.status === 200) {
                    setShippingPrice(r.data[0].total_price / 100);
                }
            });
        }
    };

    const calculateTotalPrice = () => {
        return cartItems.reduce((total, item) => total + (item.total_price * item.quantity), 0);
    };

    const handleSubmit = (event) => {
        event.preventDefault();

        if (phone.length !== 11) {
            alert('Пожалуйста, введите корректный телефонный номер.');
            return;
        }

        const total_price = cartItems.reduce((total, item) => total + (item.total_price * item.quantity), 0) + shippingPrice;

        if (total_price < minOrderPrice) {
            setShowTitle('Недостаточная сумма заказа');
            setShowText(`Заказы принимаются при сумме от ${minOrderPrice} рублей. Пожалуйста, добавьте товары в корзину для продолжения оформления.`);
            setShowModal(true);
            setIsLoading(false);
            return;
        }

        let deliveryCode;
        let deliveryAddressData;

        if (selectedOption === 'pickup') {
            deliveryCode = 1;
            deliveryAddressData = deliveryAddress;
        } else if (selectedOption === 'boxberry') {
            deliveryCode = 2;
            if (selectedPoint?.Address) {
                deliveryAddressData = selectedPoint.Address;
            } else {
                return;
            }
        } else if (selectedOption === 'online') {
            deliveryCode = 4;
        } else {
            deliveryCode = 3;
            deliveryAddressData = address.city + ' г., ' + address.street + ', д. ' + address.house + ', кв.' + address.apartment;
        }

        setIsLoading(true);

        api.updateCartClient({
            user_id: user.id.toString(),
            store_id: storeID,
            delivery_type: deliveryCode,
            delivery_address: deliveryAddressData,
            dst_office_code: selectedPoint?.Code,
            phone: parseInt(phone, 10),
            email: email,
            name: name,
            latitude: parseFloat(selectedPoint?.GPS.split(',')[0]),
            longitude: parseFloat(selectedPoint?.GPS.split(',')[1]),
            delivery_cost: shippingPrice * 100
        }).then((r) => {
            if (r.status === 201 & r.data.error === "1") {
                setShowTitle('Товары закончились...');
                setShowText(`К сожалению на момент заказа товары: ${r.data.items.join(', ')} - закончились у продавца в требуемом количестве :-(`);
                setShowModal(true);
                setIsLoading(false);
            } else {
                const items_total = cartItems.reduce((total, item) => total + (item.total_price * item.quantity), 0);

                var total_data = { amount: parseInt((items_total + shippingPrice) * 100), goods: parseInt(items_total * 100), delivery_cost: parseInt(shippingPrice * 100) };

                if (isDeliveryFree) {
                    total_data = { amount: parseInt(items_total * 100), goods: parseInt((items_total) * 100), delivery_cost: parseInt(shippingPrice * 100) };
                }

                const from_botique = sessionStorage.getItem('from_botique') !== null ? true : false;

                const order_data = {
                    customer_id: user.id.toString(),
                    pay_type: 1,
                    store_id: storeID,
                    total: total_data,
                    botique_notifications: from_botique,
                    delivery: {
                        type: deliveryCode,
                        dst_office_code: selectedPoint?.Code,
                        address: deliveryAddressData,
                        lat: parseFloat(selectedPoint?.GPS.split(',')[0]),
                        long: parseFloat(selectedPoint?.GPS.split(',')[1]),
                        phone: String(parseInt(phone, 10)),
                        email: email,
                        client_name: name,
                        inn: inn,
                        comment: comment,
                        date: selectedDate,
                        is_free: isDeliveryFree, // 27.03.2024 : Не используется
                    },
                    options: cartItems.map(item => ({
                        name: item.name,
                        nm_id: item.combo_id,
                        var1_name: item.var1_name,
                        var2_name: item.var2_name,
                        var1: item.var1_value,
                        var2: item.var2_value,
                        quantity: item.quantity,
                        price: item.price * 100,
                        total_price: item.total_price * 100,
                        weight: item.weight,
                        dimensions: [item.depth, item.height, item.width],
                        sale: parseInt((1 - item.total_price / item.price) * 100),
                        tax: item.tax
                    }))
                };

                if (deliveryCode === 2) {
                    order_data.boxberry = {
                        vid: "1",
                        shop: {
                            name: selectedPoint?.Code
                        }
                    };
                }

                api.getShopID(storeID).then(tinkoff_r => {
                    try {
                        if (tinkoff_r.status === 200) {
                            order_data.pay_type = 2;
                            order_data.tinkoff = {
                                shop_code: String(tinkoff_r.data.shop_code),
                                taxation: r.data?.taxation
                            }
                        }
                        api.createOrderV2(order_data).then(order_r => {
                            if (order_r.data.payment_url) {
                                setShowTitle('Заказ создан!');
                                setShowText('Вам отправлено сообщение со ссылкой на оплату в сообщении!');
                                setNextClose(true);
                                setShowModal(true);
                            } else {
                                api.updateCartStatus(user.id.toString(), storeID).then(r => {
                                    setShowTitle('Спасибо за заказ!');
                                    setShowText('Продавец получил ваш заказ, скоро с вами свяжутся!');
                                    setShowModal(true);
                                });
                            }
                            setIsLoading(false);
                        }).catch(() => {
                            setShowTitle('При оформлении заказа произошла ошибка :(');
                            setShowText('Попробуйте снова или напишите нам в поддержку!');
                            setShowModal(true);
                            setIsLoading(false);
                        });
                    } catch { }
                });
            }
        });
    };

    useEffect(() => {
        if (selectedOption === 'seller') {
            if (calculateTotalPrice() < freeDeliveryPrice) {
                setShippingPrice(sellerDeliveryPrice);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedOption]);

    return (
        <>
            {template === "0" ? (
                <CheckoutViewWhite
                    storeID={storeID} handleSubmit={handleSubmit}
                    orderFields={orderFields}
                    name={name}
                    setName={setName}
                    phone={phone}
                    setPhone={setPhone}
                    email={email}
                    setEmail={setEmail}
                    inn={inn}
                    setInn={setInn}
                    comment={comment}
                    setComment={setComment}
                    selectedDate={selectedDate}
                    setSelectedDate={setSelectedDate}
                    deliveryType={deliveryType}
                    selectedOption={selectedOption}
                    deliveryAddress={deliveryAddress}
                    selectedPoint={selectedPoint}
                    setSelectedPoint={setSelectedPoint}
                    setSelectedOption={setSelectedOption}
                    shippingPrice={shippingPrice}
                    setShippingPrice={setShippingPrice}
                    handleSelectPoint={handleSelectPoint}
                    address={address}
                    setAddress={setAddress}
                    cartItems={cartItems}
                    showModal={showModal}
                    isMapOpen={isMapOpen}
                    setIsMapOpen={setIsMapOpen}
                    showTitle={showTitle}
                    showText={showText}
                    closeInfoModal={nextClose}
                    isDeliveryFree={isDeliveryFree}
                    isPaymentConnected={isPaymentConnected}
                    paymentMessage={paymentMessage}
                />
            ) : template === "1" ? (
                <CheckoutViewBox
                    storeID={storeID} handleSubmit={handleSubmit}
                    orderFields={orderFields}
                    name={name}
                    setName={setName}
                    phone={phone}
                    setPhone={setPhone}
                    email={email}
                    setEmail={setEmail}
                    inn={inn}
                    setInn={setInn}
                    comment={comment}
                    setComment={setComment}
                    selectedDate={selectedDate}
                    setSelectedDate={setSelectedDate}
                    deliveryType={deliveryType}
                    selectedOption={selectedOption}
                    deliveryAddress={deliveryAddress}
                    selectedPoint={selectedPoint}
                    setSelectedPoint={setSelectedPoint}
                    setSelectedOption={setSelectedOption}
                    shippingPrice={shippingPrice}
                    setShippingPrice={setShippingPrice}
                    handleSelectPoint={handleSelectPoint}
                    address={address}
                    setAddress={setAddress}
                    cartItems={cartItems}
                    showModal={showModal}
                    isMapOpen={isMapOpen}
                    setIsMapOpen={setIsMapOpen}
                    showTitle={showTitle}
                    showText={showText}
                    closeInfoModal={nextClose}
                    isDeliveryFree={isDeliveryFree}
                    isPaymentConnected={isPaymentConnected}
                    paymentMessage={paymentMessage}
                />
            ) : template === "2" ? (
                <CheckoutViewList
                    storeID={storeID} handleSubmit={handleSubmit}
                    orderFields={orderFields}
                    name={name}
                    setName={setName}
                    phone={phone}
                    setPhone={setPhone}
                    email={email}
                    setEmail={setEmail}
                    inn={inn}
                    setInn={setInn}
                    comment={comment}
                    setComment={setComment}
                    selectedDate={selectedDate}
                    setSelectedDate={setSelectedDate}
                    deliveryType={deliveryType}
                    selectedOption={selectedOption}
                    deliveryAddress={deliveryAddress}
                    selectedPoint={selectedPoint}
                    setSelectedPoint={setSelectedPoint}
                    setSelectedOption={setSelectedOption}
                    shippingPrice={shippingPrice}
                    setShippingPrice={setShippingPrice}
                    handleSelectPoint={handleSelectPoint}
                    address={address}
                    setAddress={setAddress}
                    cartItems={cartItems}
                    showModal={showModal}
                    isMapOpen={isMapOpen}
                    setIsMapOpen={setIsMapOpen}
                    showTitle={showTitle}
                    showText={showText}
                    closeInfoModal={nextClose}
                    isDeliveryFree={isDeliveryFree}
                    isPaymentConnected={isPaymentConnected}
                    paymentMessage={paymentMessage}
                />
            ) : template === "4" ? (
                <CheckoutViewAstroknight
                    storeID={storeID} handleSubmit={handleSubmit}
                    orderFields={orderFields}
                    name={name}
                    setName={setName}
                    phone={phone}
                    setPhone={setPhone}
                    email={email}
                    setEmail={setEmail}
                    inn={inn}
                    setInn={setInn}
                    comment={comment}
                    setComment={setComment}
                    selectedDate={selectedDate}
                    setSelectedDate={setSelectedDate}
                    deliveryType={deliveryType}
                    selectedOption={selectedOption}
                    deliveryAddress={deliveryAddress}
                    selectedPoint={selectedPoint}
                    setSelectedPoint={setSelectedPoint}
                    setSelectedOption={setSelectedOption}
                    shippingPrice={shippingPrice}
                    setShippingPrice={setShippingPrice}
                    handleSelectPoint={handleSelectPoint}
                    address={address}
                    setAddress={setAddress}
                    cartItems={cartItems}
                    showModal={showModal}
                    isMapOpen={isMapOpen}
                    setIsMapOpen={setIsMapOpen}
                    showTitle={showTitle}
                    showText={showText}
                    closeInfoModal={nextClose}
                    isDeliveryFree={isDeliveryFree}
                    isPaymentConnected={isPaymentConnected}
                    paymentMessage={paymentMessage}
                />
            ) : (
                <CheckoutViewWhite
                    storeID={storeID} handleSubmit={handleSubmit}
                    orderFields={orderFields}
                    name={name}
                    setName={setName}
                    phone={phone}
                    setPhone={setPhone}
                    email={email}
                    setEmail={setEmail}
                    inn={inn}
                    setInn={setInn}
                    comment={comment}
                    setComment={setComment}
                    selectedDate={selectedDate}
                    setSelectedDate={setSelectedDate}
                    deliveryType={deliveryType}
                    selectedOption={selectedOption}
                    deliveryAddress={deliveryAddress}
                    selectedPoint={selectedPoint}
                    setSelectedPoint={setSelectedPoint}
                    setSelectedOption={setSelectedOption}
                    shippingPrice={shippingPrice}
                    setShippingPrice={setShippingPrice}
                    handleSelectPoint={handleSelectPoint}
                    address={address}
                    setAddress={setAddress}
                    cartItems={cartItems}
                    showModal={showModal}
                    isMapOpen={isMapOpen}
                    setIsMapOpen={setIsMapOpen}
                    showTitle={showTitle}
                    showText={showText}
                    closeInfoModal={nextClose}
                    isDeliveryFree={isDeliveryFree}
                    isPaymentConnected={isPaymentConnected}
                    paymentMessage={paymentMessage}
                />
            )}
            {isLoading && <Loading />}
        </>
    );
}

export default CheckoutPage;
