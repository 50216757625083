import { useNavigate } from 'react-router-dom';
import { v4 as uuid } from "uuid";
import styles from './RecommendationCard.module.css';
import ImageSlider from "./White/CatalogView/ImageSlider";
import cartImg from './add-2-cart.svg';

const RecommendationCard = ({ product, storeID, hideRecommendation }) => {
    const nav = useNavigate();
    const formatPriceWithThousandSeparator = (price) => {
        return Number(price).toFixed().toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    };

    return (
        <>
            <div className={styles.recommendations}><span>Возможно, вас заинтересует:</span></div>
            <div className={styles.productCard} key={uuid()} onClick={() => { nav(`/store/${storeID}/product/${product.product}`); }}>
                <ImageSlider images={product.photos} />
                <div className={styles.price}>
                    {product.total_price !== product.price ? (
                        <div className={styles.priceLeft}>
                            <span className={styles.originalPrice}>{formatPriceWithThousandSeparator(product.total_price)}</span>
                            <span className={styles.discountPrice}>{formatPriceWithThousandSeparator(product.price)}</span>
                        </div>
                    ) : (
                        <span className={styles.originalPrice}>{formatPriceWithThousandSeparator(product.total_price)}</span>
                    )}
                    <img src={cartImg} alt="Добавить в корзину" onClick={(event) => {
                        event.stopPropagation();
                        hideRecommendation();
                    }} />
                </div>

                <p className={styles.description}>{`${product.name} — ${product.description}`.substring(0, 128)}...</p>
            </div>
        </>
    );
};

export default RecommendationCard;