import axios from "axios";
import { baseAPIURL, baseDeliveryAPIURL } from "../config/config";


const api = {
    // Marketplace API
    getMarket: async () => {
        try {
            const response = await axios.get(`${baseAPIURL}/telegram/products/`).catch((e) => {
                console.log(e.response);
            });
            return response;
        } catch (error) {
            throw error;
        }
    },
    // Catalog API
    getCatalog: async (storeID) => {
        try {
            // const response = await axios.get(`${baseAPIURL}/store/${storeID}/data/`
            const response = await axios.get(`${baseAPIURL}/telegram/store/${storeID}/catalog/`
            ).catch((e) => {
                console.log(e.response);
            });
            return response;
        } catch (error) {
            throw error;
        }
    },
    // Product Data API
    getProduct: async (productID) => {
        try {
            // const response = await axios.get(`${baseAPIURL}/product/${productID}/data/`
            const response = await axios.get(`${baseAPIURL}/telegram/product/${productID}/data/`
            ).catch((e) => {
                console.log(e.response);
            });
            return response;
        } catch (error) {
            throw error;
        }
    },
    // Cart API
    addCartItem: async (data) => {
        try {
            const response = await axios.post(`${baseAPIURL}/cart/item/`,
                data,
                { headers: { "Content-Type": "application/json" } }
            ).catch((e) => {
                console.log(e.response);
            });

            return response;
        } catch (error) {
            throw error;
        }
    },
    removeCartItem: async (cartItemID) => {
        try {
            const response = await axios.delete(`${baseAPIURL}/cart/item/${cartItemID}/`
            ).catch((e) => {
                console.log(e.response);
            });

            return response;
        } catch (error) {
            throw error;
        }
    },
    increaseCartItemQuantity: (cartItemID) => {
        try {
            const response = axios.put(`${baseAPIURL}/cart/item/`,
                { cart_item_id: cartItemID },
                { headers: { "Content-Type": "application/json" } }
            ).catch((e) => {
                console.log(e.response);
            });

            return response;
        } catch (error) {
            throw error;
        }
    },
    decreaseCartItemQuantity: async (cartItemID) => {
        try {
            const response = await axios.post(`${baseAPIURL}/cart/item/${cartItemID}/`
            ).catch((e) => {
                console.log(e.response);
            });

            return response;
        } catch (error) {
            throw error;
        }
    },
    getCartInfo: async (userID, storeID) => {
        try {
            const response = await axios.get(`${baseAPIURL}/cart/`,
                {
                    params: { user_id: userID, store_id: storeID },
                }
            ).catch((e) => {
                console.log(e.response);
            });
            return response;
        } catch (error) {
            throw error;
        }
    },
    getCart: async (userID, storeID) => {
        try {
            const response = await axios.get(`${baseAPIURL}/cart/items`,
                {
                    params: { user_id: userID, store_id: storeID },
                }
            ).catch((e) => {
                console.log(e.response);
            });
            return response;
        } catch (error) {
            throw error;
        }
    },
    getCartCount: async (userID, storeID) => {
        try {
            const response = await axios.get(`${baseAPIURL}/cart/count`,
                {
                    params: { user_id: userID, store_id: storeID },
                }
            ).catch((e) => {
                console.log(e.response);
            });
            return response;
        } catch (error) {
            throw error;
        }
    },
    updateCartClient: async (data) => {
        try {
            const response = await axios.put(`${baseAPIURL}/cart/update/`,
                data,
                { headers: { "Content-Type": "application/json" } }
            ).catch((e) => {
                console.log(e.response);
            });
            return response
        } catch (error) {
            throw error;
        }
    },
    updateCartStatus: async (userID, storeID) => {
        try {
            const response = await axios.put(`${baseAPIURL}/cart/status-update/`,
                { user_id: userID, store_id: storeID },
                { headers: { "Content-Type": "application/json" } }
            ).catch((e) => {
                console.log(e.response);
            });
            return response;
        } catch (error) {
            throw error;
        }
    },
    recommendProduct: async (userID, storeID) => {
        return await axios.get(`${baseAPIURL}/cart/recommend-product/`,
            { params: { user_id: userID, store_id: storeID } }
        ).catch((e) => {
            console.log(e.response);
        });
    },
    minOrderPrice: async (storeID) => {
        return await axios.get(`${baseAPIURL}/telegram/store/${storeID}/min-order-price/`,
        ).catch((e) => {
            console.log(e.response);
        });
    },
    getFreeDeliveryPrice: async (storeID) => {
        return await axios.get(`${baseAPIURL}/telegram/store/${storeID}/free-delivery-price/`,
        ).catch((e) => {
            console.log(e.response);
        });
    },
    // Payment info
    getShopID: async (storeID) => {
        try {
            const response = await axios.get(`https://tinkoff-payments.thebotique.ru/api/v1/points/short/shop/${storeID.slice(0, 18)}`).catch((e) => {
                console.log(e.response);
            });
            return response;
        } catch (error) {
            throw error;
        }
    },
    getPaymentMessage: async (storeID) => {
        return await axios.get(`${baseAPIURL}/telegram/store/${storeID}/payment-message/`,
        ).catch((e) => {
            console.log(e.response);
        });
    },
    // Delivery Types
    getDeliveryInfo: async (storeID) => {
        try {
            const response = await axios.get(`${baseAPIURL}/store/${storeID}/delivery-info/`)
                .catch((e) => {
                    console.log(e.response);
                })
            return response
        } catch (error) {
            throw error
        }
    },
    // Order form info
    getOrderForm: async (storeID) => {
        try {
            const response = await axios.get(`${baseAPIURL}/telegram/store/${storeID}/order-form/`).catch((e) => {
                console.log(e.response);
            })
            return response;
        } catch (error) {
            throw error;
        }
    },
    // Order API
    createOrderV2: async (order) => {
        try {
            const response = await axios.post(`https://orders.thebotique.ru/api/v1/orders`,
                order,
                { headers: { "Content-Type": "application/json" } }
            ).catch((e) => {
                console.log(e.response);
            })
            return response
        } catch (error) {
            throw error
        }
    },
    createOrder: async (order) => {
        try {
            const response = await axios.post(`https://orders-gateway.thebotique.ru/api/v1/create-order`,
                order,
                { headers: { "Content-Type": "application/json" } }
            ).catch((e) => {
                console.log(e.response);
            })
            return response
        } catch (error) {
            throw error
        }
    },
    calculateShippingPrice: async (storeID, box_sizes, targetCode) => {
        try {
            const response = await axios.post(
                `${baseDeliveryAPIURL}/stores/${storeID}/delivery/calculation`,
                {
                    "box_sizes": box_sizes,
                    "target_stop": targetCode,
                },
                { headers: { "Content-Type": "application/json" } }
            ).catch((e) => {
                console.log(e.response);
            });
            return response;
        } catch (error) {
            throw error;
        }
    },
    createBoxberryOrder: async (storeID, data) => {
        try {
            const response = await axios.post(
                `${baseDeliveryAPIURL}/stores/${storeID}/parsel/create`,
                data,
                { headers: { "Content-Type": "application/json" } }
            ).catch((e) => {
                console.log(e.response);
            });
            return response;
        } catch (error) {
            throw error;
        }
    },
    // Change Stock API
    changeStock: async (data) => {
        try {
            const response = await axios.put(`${baseAPIURL}/order/set-stock/`,
                data,
                { headers: { "Content-Type": "application/json" } }
            ).catch((e) => {
                console.log(e.response);
            })
            return response
        } catch (error) {
            throw error;
        }
    }
};

export default api;