import React from 'react';
import { v4 as uuid } from "uuid";
import styles from './BannersComponent.module.css';

const BannersComponent = ({ banners, divRef }) => {

    return (
        <>
            {
                banners.length === 0 ? null :
                    <div className={styles.banners} ref={divRef} >
                        {
                            banners.map((banner) => {
                                if (banner.text === "") {
                                    return <Banner key={uuid()} imageUrl={banner.img} altText={banner.text} />
                                }
                                return <Promotion key={uuid()} imageUrl={banner.img} altText={banner.text} />
                            })
                        }
                    </ div>
            }
        </>
    );
};

const Banner = ({ imageUrl, altText }) => {
    return (
        <div className={styles.banner}>
            <img src={imageUrl} alt={altText} />
        </div>
    );
};

function Promotion({ imageUrl, altText }) {
    return (
        <div className={styles.promo}>
            <img src={imageUrl} alt="" />
            <p>{altText}</p>
        </div>
    );
}

export default BannersComponent;
