import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styles from './CategoryComponentV2.module.css';

const CategoryComponentV2 = ({ categories, subcategories, handleSelectCategory, handleSelectSubCategory, defaultCategory, defaultSubCategory }) => {
    const [selectedCategory, setSelectedCategory] = useState(defaultCategory === null ? 'Все' : defaultCategory);
    const [selectedSubcategory, setSelectedSubcategory] = useState(defaultSubCategory);
    const [displayedSubcategories, setDisplayedSubcategories] = useState([]);

    useEffect(() => {
        if (selectedCategory) {
            if (subcategories.length > 1) {
                setDisplayedSubcategories(subcategories);
            } else {
                setDisplayedSubcategories([]);
            }
        }
    }, [selectedCategory, selectedSubcategory, subcategories]);

    const handleCategoryClick = (category) => {
        setSelectedCategory(category);
        setSelectedSubcategory(null);
        handleSelectCategory({ target: { textContent: category } });
    };

    const handleSubcategoryClick = (subcategory) => {
        setSelectedSubcategory(subcategory);
        handleSelectSubCategory({ target: { textContent: subcategory } });
    };

    return (
        <div className={styles.container}>
            {categories.map((category, index) => (
                <div key={index} className={styles.categoryContainer}>
                    <div
                        className={`${styles.category} ${category === selectedCategory ? styles.selected : ''}`}
                        onClick={() => handleCategoryClick(category)}
                    >
                        <h3>{category}</h3>
                    </div>
                    {category === selectedCategory && displayedSubcategories.length > 0 && (
                        <div className={styles.subcategoriesContainer}>
                            <div className={styles.subcategories}>
                                {displayedSubcategories.map(subcategory => (
                                    <div
                                        key={subcategory}
                                        className={`${styles.subcategory} ${subcategory === selectedSubcategory ? styles.selectedSubcategory : ''}`}
                                        onClick={() => handleSubcategoryClick(subcategory)}
                                    >
                                        {subcategory}
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}
                </div>
            ))}
        </div>
    );
};

CategoryComponentV2.propTypes = {
    categories: PropTypes.arrayOf(PropTypes.string).isRequired,
    subcategories: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.string)).isRequired,
    handleSelectCategory: PropTypes.func.isRequired,
    handleSelectSubCategory: PropTypes.func.isRequired,
};

export default CategoryComponentV2;
