import React from 'react';
import Modal from 'react-modal';
import styles from './InfoModal.module.css';

Modal.setAppElement('#root');

function InfoModal({ showModal, onClose, title, text }) {
    if (!showModal) {
        return null;
    }

    const closeModal = () => {
        onClose();
    };

    return (
        <Modal
            isOpen={showModal}
            onRequestClose={closeModal}
            className={styles.modalContent}
            overlayClassName={styles.modalOverlay}
            contentLabel="Информационное модальное окно"
        >
            <h2>{title}</h2>
            <p>{text}</p>
            <button className={styles.closeButton} onClick={closeModal}>
                Закрыть
            </button>
        </Modal>
    );
}

export default InfoModal;
