import React from 'react';
import styles from './ProductLabelComponent.module.css'

const ProductLabelComponent = ({ labelStyle }) => {
    return (
        <div
            className={styles.labelPreview}
            style={{
                backgroundColor: labelStyle.label_color,
                color: labelStyle.text_color,
                borderColor: labelStyle.border_color,
                borderStyle: labelStyle.border_style,
                borderWidth: labelStyle.border_width,
            }}
        >
            {labelStyle.text}
        </div>
    );
};

export default ProductLabelComponent;