import React from 'react';
import Game from './components/Game/Game';

const GamePage = () => {
    return (
        <div>
            <Game />
        </div>
    );
};

export default GamePage;
