import React from 'react';
import styles from './Window.module.css';

const Window = ({ xPosition, screenWidth, yPosition, wallHeight }) => {
    const windowWidth = 200; // Ширина окна

    // Рассчитываем ширину левой и правой части стены
    const leftWallWidth = xPosition;
    const rightWallWidth = screenWidth - xPosition - windowWidth;

    return (
        <div className={styles.wall} style={{ height: wallHeight, marginTop: yPosition }}>
            <div className={styles.leftWall} style={{ width: leftWallWidth, height: wallHeight }}></div>
            <div className={styles.window} style={{ width: windowWidth, height: wallHeight }}></div>
            <div className={styles.rightWall} style={{ width: rightWallWidth, height: wallHeight }}></div>
        </div>
    );
};

export default Window;
