import React, { useState } from "react";
import arrow from "../assets/Frame 1232.svg";
import atten from "../assets/attention.svg";
import styles from "./SearchComponent.module.css";

function SearchComponent({ doSearch, doClear, numProducts }) {
    const [menuFlag, setMenuFlag] = useState(false);
    const [extraclass, setExtraClass] = useState('');
    const [searchStatus, setSeacrchStatus] = useState(false);
    const [searchValue, setSearchValue] = useState('');

    const handlerOpen = () => {
        setMenuFlag(true);
        setExtraClass('');
    };

    const handlerClose = () => {
        setExtraClass(styles.searchContainerDis);
        setTimeout(setMenuFlag, 400, false);
    };

    const handlerSearch = () => {
        if (searchValue.length > 2) {
            setSeacrchStatus(true);
            doSearch(searchValue);
        }
    };

    const handlerInput = (e) => {
        setSearchValue(e.target.value);
    };

    const handlerInputKey = (e) => {
        if (e.key === 'Enter') {
            if (searchValue === '') {
                return;
            }
            
            if (searchValue.length > 2) {
                setSeacrchStatus(true);
                doSearch(searchValue);
            }
        }
    };

    const handlerClearInput = () => {
        setSearchValue('');
        setSeacrchStatus(false);
        handlerClose();
        doClear();
    };

    return (
        <>
            <button className={styles.searchIcon} onClick={handlerOpen}>
                {searchStatus ? <img src={atten} alt="" className={styles.searchIconAtten}></img> : null}
            </button>
            {menuFlag ? <div className={`${styles.searchContainer} ${extraclass}`} >
                <img src={arrow} alt="" className={styles.searchContainerArrow} onClick={handlerClose}></img>
                <div className={styles.searchContainerContent}>
                    <div className={styles.searchContainerBtn}></div>
                    <input type="text" className={styles.searchContainerInput} placeholder="Найти товар" onChange={handlerInput} value={searchValue} onKeyDown={handlerInputKey}></input>
                    <div className={styles.searchContainerContentArrow} onClick={handlerSearch}></div>
                </div>
                {searchStatus ? <><div className={styles.searchContainerText}>По вашему запросу нашлось {numProducts ? numProducts : 0} товаров</div>
                    <span className={styles.searchContainerReset} onClick={handlerClearInput}>сбросить поиск</span></> : null}
            </div> : null}
        </>
    )
}
export default SearchComponent; 