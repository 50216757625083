import axios from "axios";
import { baseAPIURL } from "../../../config/config";


const rediectApi = {
    getStoreID: async (token) => {
        try {
            const response = await axios.get(`${baseAPIURL}/telegram/redirect/`, { params: { token: token } }).catch((e) => { });
            return response;
        } catch (error) {
            throw error;
        }
    },
    getStoreAndProductID: async (token) => {
        try {
            const response = await axios.get(`${baseAPIURL}/telegram/redirect-to-product/`, { params: { token: token } }).catch((e) => { });
            return response;
        } catch (error) {
            throw error;
        }
    }
}

export default rediectApi;