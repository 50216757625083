import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ClosedSign from '../../components/ClosedSign';
import animation from "./animation.png";
import rediectApi from './api/api';
import styles from './CommonStorePage.module.css';

const CommonStorePage = () => {
    const [flag, setFlag] = useState(true);
    const nav = useNavigate();

    useEffect(() => {
        const tg = window.Telegram.WebApp;
        let initDataUnsafe = tg.initDataUnsafe;

        const prefix = 'p_';
        const index = initDataUnsafe.start_param.indexOf(prefix);

        if (index !== -1) {

            const token = initDataUnsafe.start_param.substring(index + prefix.length);
            rediectApi.getStoreAndProductID(token).then((r) => {
                if (r.status === 200) {
                    sessionStorage.setItem('from_botique', true);
                    nav(`/store/${r.data.store_id}/product/${r.data.product_id}`);

                } else if (r.status === 204) {
                    setFlag(false);
                }
            });
        } else {
            rediectApi.getStoreID(initDataUnsafe.start_param).then((r) => {
                if (r.status === 200) {
                    sessionStorage.setItem('from_botique', true);
                    nav(`/store/${r.data.id}`);

                } else if (r.status === 204) {
                    setFlag(false);
                }
            });
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            {true ? <div className={styles.loading}><img src={animation} alt="загрузка" /></div> : <ClosedSign text={"Магазин временно не работает"} />}
        </>
    );
};

export default CommonStorePage;
