import PropTypes from 'prop-types';
import React from 'react';
import styles from './Modal.module.css';

const Modal = ({ imageUrl, onClose }) => {
    if (!imageUrl) { return null };

    return (
        <div className={styles.overlay} onClick={onClose}>
            <div className={styles.modalContent} onClick={(e) => e.stopPropagation()}>
                <img src={imageUrl} alt="Expanded View" className={styles.modalImage} />
            </div>
        </div>
    );
};

Modal.propTypes = {
    imageUrl: PropTypes.string,
    onClose: PropTypes.func.isRequired,
};

export default Modal;
